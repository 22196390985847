import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { start } from 'qiankun'
import BiasScanner from 'pages/biasScanner'

const PrivateRoutes = () => {
  useEffect(() => {
    start({
      prefetch: true
    })
  }, [])

  return (
    <div>
      <Routes>
        <Route path='/bias-scanner' element={<BiasScanner />} />
        <Route path='/*' element={<Navigate to='/bias-scanner' />} />
      </Routes>
      <div id='tandem-root'></div>
    </div>
  )
}

export default PrivateRoutes
