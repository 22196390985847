import React, { useContext } from 'react'
import Signup from '../pages/signup'
import { styled } from '@mui/material/styles'
import { Route, Routes, Navigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import PrivateRoutes from './PrivateRoutes'
import Login from '../pages/login'
import { AuthenticationContext } from '../context/AuthenticationContext'
import { FullStory, isInitialized } from '@fullstory/browser'

const BodyWrapper = styled('div')(() => ({
  minHeight: '100vh',
  minWidth: 'calc(100vw - 64px)',
  position: 'relative',
  paddingLeft: '64px'
}))

const BsWrapper = styled('div')(() => ({
  minHeight: '100vh',
  minWidth: '100vw',
  padding: 0
}))

const Header = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 128,
  padding: 32
}))

const Sidebar = styled('div')(() => ({
  position: 'absolute',
  alignItems: 'center',
  height: 'calc(100% - 16px)',
  width: 64,
  paddingTop: 16,
  left: 0,
  borderRight: '1px solid #E5E5E5',
  backgroundColor: 'white'
}))

const PublicRoutes = () => {
  const { isAuthenticated, currentAccount } = useContext(AuthenticationContext)

  if (isAuthenticated && currentAccount && isInitialized()) {
    FullStory('setIdentity', {
      uid: currentAccount?.id,
      properties: {
        role_str: 'na',
        email: currentAccount?.email,
        displayName: `${currentAccount?.name} ${currentAccount?.lastName}`,
        impersonating_bool: false,
        company_str: currentAccount?.organizationName,
        jobAccess_str: 'na',
        ehiAccess_str: 'na'
      }
    })
  }

  return (
    <BodyWrapper>
      {isAuthenticated ? (
        <>
          <Grid container>
            <Grid item>
              <Sidebar>
                <img
                  src={'/MathisonLogo.png'}
                  alt='Mathison Logo'
                  width={32}
                  style={{ marginBottom: 'auto', paddingLeft: '16px' }}
                  height={32}
                />
              </Sidebar>
            </Grid>
            <Grid item sx={{ padding: '46px 42px' }} xs>
              <Routes>
                <Route
                  path='/*'
                  element={
                    currentAccount ? <PrivateRoutes /> : <div>Loading...</div>
                  }
                />
              </Routes>
            </Grid>
          </Grid>
        </>
      ) : (
        <BsWrapper>
          <Header>
            <img
              src={'/MathisonColourLogo.png'}
              alt='Mathison Logo'
              width={192}
              style={{ marginBottom: 'auto' }}
              height={32}
            />
          </Header>
          <Routes>
            <Route path='/signup' element={<Signup />} />
            <Route path='/login' element={<Login />} />
            <Route path='/*' element={<Navigate to='signup' />} />
          </Routes>
        </BsWrapper>
      )}
    </BodyWrapper>
  )
}

export default PublicRoutes
