import { gql } from '@apollo/client'

export const ME = gql`
  query {
    me {
      email
      id
      firstName
      lastName
      organizationName
    }
  }
`

export const SIGN_UP = gql`
  mutation SignUp(
    $firstName: String!
    $lastName: String!
    $organizationName: String!
    $email: String!
    $jobTitle: String!
    $hearAboutMathison: String
    $utmSource: String
    $utmMedium: String
    $utmCampaign: String
  ) {
    signUp(
      input: {
        firstName: $firstName
        lastName: $lastName
        organizationName: $organizationName
        email: $email
        jobTitle: $jobTitle
        hearAboutMathison: $hearAboutMathison
        utmSource: $utmSource
        utmMedium: $utmMedium
        utmCampaign: $utmCampaign
      }
    ) {
      token
    }
  }
`

export const LOGIN = gql`
  mutation Login($email: String!) {
    login(input: { email: $email }) {
      token
    }
  }
`
