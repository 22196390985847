import {
  Button,
  ButtonProps,
  FormControl,
  FormLabel,
  TextField,
  Box,
  BoxProps,
  Typography,
  Link
} from '@mui/material'
import { Color } from '@mathison-inc/components'
import ErrorIcon from '@mui/icons-material/Error'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticationContext } from '../../context/AuthenticationContext'
import { styled } from '@mui/material/styles'

const StyledButton = styled(Button)<ButtonProps>(() => ({
  fontFamily: 'Roboto',
  lineHeight: '24px',
  color: '#FFFFFF',
  padding: '12px 16px',
  marginTop: '32px',
  width: '100px',
  backgroundColor: '#000000',
  '&:hover': {
    backgroundColor: '#F2F3F3',
    color: '#050B0E'
  },
  '&:disabled': {
    backgroundColor: '#F2F3F3',
    color: '#a2a2a2'
  }
}))

const ErrorMessage = styled(Box)<BoxProps>(() => ({
  border: '1px solid #FEE',
  backgroundColor: '#FEE',
  borderRadius: '8px',
  padding: '8px 16px',
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 'bold',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}))

interface FormFieldProps {
  label: string
  name: string
  type?: string
  value?: string
  onChange?: (e: any) => void
  onFocus?: (e: any) => void
}

const FormField = ({
  label,
  name,
  type,
  value,
  onChange,
  onFocus
}: FormFieldProps) => {
  return (
    <TextField
      label={label}
      value={value}
      variant='filled'
      required
      InputLabelProps={{
        sx: {
          fontWeight: 400,
          lineHeight: '32px',
          '& .Mui-focused.MuiFilledInput-root': { border: 'none' },
          '& .MuiInputLabel-asterisk': { color: Color.error }
        }
      }}
      inputProps={{
        sx: {
          paddingLeft: '10px',
          fontWeight: 700,
          fontFamily: 'Roboto'
        }
      }}
      sx={{
        height: '64px'
      }}
      InputProps={{
        sx: { height: '64px', border: '0px' }
      }}
      fullWidth
      type={type}
      name={name}
      onChange={onChange}
      onFocus={onFocus}
    />
  )
}

const Login = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState<boolean>(false)
  const navigate = useNavigate()
  const { setIsAuthenticated, login } = useContext(AuthenticationContext)

  const emailIsValid = () => {
    const re: RegExp = /\S+@\S+\.\S+/
    return re.test(email)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (!emailIsValid()) {
      setError(true)
      return
    }
    const { data } = await login({ variables: { email } })
    const { token } = data.login
    localStorage.setItem('token', token)
    setIsAuthenticated(true)
    navigate('/')
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50vh'
      }}
    >
      <Box
        sx={{
          border: '1px solid #E5E5E5',
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          padding: '64px',
          width: '100%',
          maxWidth: '420px'
        }}
      >
        <Typography
          sx={{
            paddingBottom: '48px',
            color: '#050B0E',
            fontSize: '20px',
            fontWeight: 'bold',
            letterSpacing: '-0.4px'
          }}
        >
          Log in
        </Typography>
        <form onSubmit={async (e: any) => handleSubmit(e)} noValidate>
          <FormControl sx={{ width: '100%' }}>
            <FormField
              label='Work Email'
              name='email'
              type='email'
              value={email}
              onChange={(e: any) => {
                setEmail(e.target.value)
              }}
              onFocus={() => setError(false)}
            />

            <Box sx={{ marginTop: '10px', maxWidth: '160px' }}>
              {error && (
                <ErrorMessage>
                  <ErrorIcon color='error' sx={{ marginRight: '10px' }} />
                  Invalid email
                </ErrorMessage>
              )}
            </Box>

            <FormLabel>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  color: '#747779',
                  fontSize: '14px',
                  fontWeight: '400',
                  paddingTop: '16px'
                }}
              >
                By continuing you agree to our{' '}
                <Link
                  href='https://app.mathison.io/statement/legal/privacy-policy'
                  underline='always'
                  target='_blank'
                >
                  Privacy Policy
                </Link>{' '}
                and{' '}
                <Link
                  href='https://www.mathison.io/terms-and-conditions'
                  underline='always'
                  target='_blank'
                >
                  Terms of Service
                </Link>
              </Typography>
            </FormLabel>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end'
              }}
            >
              <StyledButton type='submit'>Next</StyledButton>
            </Box>
          </FormControl>
        </form>
      </Box>
    </Box>
  )
}

export default Login
