import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const createClient = () => {
  const httpLink = createHttpLink({
    uri: `${
      process.env.REACT_APP_ENDPOINT_URL || 'http://localhost:7400'
    }/graphql`
  })

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token')

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  })

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  })
  // eslint-disable-next-line
}

const client = createClient()
export default client
