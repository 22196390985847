import React, { createContext, useEffect, useMemo } from 'react'
import useAuthentication from '../hooks/useAuthentication'

export type AuthenticationContextData = {
  isAuthenticated: boolean | undefined
  setIsAuthenticated: (isAuthenticated: boolean) => void
  currentAccount: any
  signup: (input: any) => Promise<any>
  login: (input: any) => Promise<any>
}

export const AuthenticationContext = createContext<AuthenticationContextData>({
  isAuthenticated: undefined,
  setIsAuthenticated: _ => {},
  currentAccount: undefined,
  signup: _ => Promise.resolve(),
  login: _ => Promise.resolve()
})

export const AuthenticationProvider = ({ children }: any): JSX.Element => {
  const { isAuthenticated, setIsAuthenticated, currentAccount, signup, login } =
    useAuthentication()

  useEffect(() => {
    if (currentAccount === null) {
      localStorage.removeItem('token')
      setIsAuthenticated(false)
    }
  }, [currentAccount, setIsAuthenticated])
  const store: AuthenticationContextData = useMemo(
    () => ({
      isAuthenticated,
      setIsAuthenticated,
      currentAccount,
      signup,
      login
    }),
    [isAuthenticated, setIsAuthenticated, currentAccount, signup, login]
  )
  return (
    <AuthenticationContext.Provider value={store}>
      {children}
    </AuthenticationContext.Provider>
  )
}
