import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { registerMicroApps } from 'qiankun'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '@mathison-inc/components'
import client from './utils/createApolloClient'
import * as FullStory from './utils/fullStory'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

FullStory.initialize()

registerMicroApps(
  [
    {
      name: 'tandem',
      entry: process.env.REACT_APP_TANDEM_ENTRY ?? '//localhost:7301',
      container: '#tandem-root',
      activeRule: '/bias-scanner',
      props: {
        client: client
      }
    }
  ],
  {
    beforeLoad: app => {
      return Promise.resolve()
    },
    beforeMount: [
      app => {
        return Promise.resolve()
      }
    ]
  }
)

root.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Router>
  </React.StrictMode>
)
