import React from 'react'
import './App.css'
import Routes from './routes'
import client from './utils/createApolloClient'
import { ApolloProvider } from '@apollo/client'
import { AuthenticationProvider } from './context/AuthenticationContext'

const App = () => {
  return (
    <div
      style={{
        backgroundImage: 'url(/Background.png)',
        backgroundSize: 'cover',
        minHeight: '100vh',
        minWidth: 1280
      }}
    >
      <div className='App'>
        <ApolloProvider client={client}>
          <AuthenticationProvider>
            <Routes />
          </AuthenticationProvider>
        </ApolloProvider>
      </div>
    </div>
  )
}

export default App
