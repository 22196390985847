import { useLazyQuery, useMutation } from '@apollo/client'
import { useState, useEffect } from 'react'
import { LOGIN, ME, SIGN_UP } from './gql'

const useAuthentication = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('token')
  )

  const [getMe, { data }] = useLazyQuery(ME)
  const [signup] = useMutation(SIGN_UP)
  const [login] = useMutation(LOGIN)
  useEffect(() => {
    if (isAuthenticated) {
      getMe()
    }
  }, [isAuthenticated, getMe])

  return {
    isAuthenticated,
    setIsAuthenticated,
    currentAccount: data?.me,
    signup,
    login
  }
}

export default useAuthentication
