import {
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Grid,
  Typography,
  Box
} from '@mui/material'
import React, { useContext, useState } from 'react'

import { AuthenticationContext } from '../../context/AuthenticationContext'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Color } from '@mathison-inc/components'
import { useNavigate } from 'react-router-dom'

const Rule = ({ content }: any) => {
  return (
    <Box sx={{ marginBottom: 2 }}>
      <CheckCircleIcon
        sx={{ color: Color.success, fontSize: '13px', marginRight: 1.5 }}
      />
      <Typography variant='body1' component='span'>
        {content}
      </Typography>
    </Box>
  )
}
interface FormFieldProps {
  label: string
  name: string
  type?: string
}

const FormField = ({ label, name, type }: FormFieldProps) => {
  return (
    <TextField
      label={label}
      variant='filled'
      required
      InputLabelProps={{
        sx: {
          fontWeight: 400,
          lineHeight: '32px',
          '& .Mui-focused.MuiFilledInput-root': { border: 'none' },
          '& .MuiInputLabel-asterisk': { color: Color.error }
        }
      }}
      inputProps={{
        sx: {
          paddingLeft: '10px',
          fontWeight: 700,
          fontFamily: 'Roboto'
        }
      }}
      sx={{
        height: '64px'
      }}
      InputProps={{
        sx: { height: '64px', border: '0px' }
      }}
      fullWidth
      type={type}
      name={name}
    />
  )
}

const Signup = () => {
  const { signup, setIsAuthenticated } = useContext(AuthenticationContext)
  const [emailError, setEmailError] = useState('')
  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [organizationNameError, setOrganizationNameError] = useState('')
  const [jobTitleError, setJobTitleError] = useState('')

  const reset = () => {
    setEmailError('')
    setFirstNameError('')
    setLastNameError('')
    setOrganizationNameError('')
    setJobTitleError('')
  }
  const navigate = useNavigate()
  const handleSubmit = async (e: any) => {
    reset()
    const firstName = e.target.firstName.value
    const lastName = e.target.lastName.value
    const email = e.target.email.value
    const organizationName = e.target.orgName.value
    const jobTitle = e.target.role.value
    const hearAboutMathison = e.target.howDidYouHear.value

    e.preventDefault()
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    if (!regex.test(email)) {
      setEmailError('Invalid email format')
    }

    if (firstName === '') {
      setFirstNameError('First name is required')
    }

    if (lastName === '') {
      setLastNameError('Last name is required')
    }

    if (organizationName === '') {
      setOrganizationNameError('Organization name is required')
    }

    if (jobTitle === '') {
      setJobTitleError('Job title is required')
    }

    if (
      email === '' ||
      firstName === '' ||
      lastName === '' ||
      organizationName === '' ||
      jobTitle === ''
    ) {
      return
    }

    const url = new URL(window.location.href)
    const utmSource = url.searchParams.get('utm_source')
    const utmMedium = url.searchParams.get('utm_medium')
    const utmCampaign = url.searchParams.get('utm_campaign')

    const { data } = await signup({
      variables: {
        firstName,
        lastName,
        email,
        organizationName,
        jobTitle,
        hearAboutMathison,
        utmSource,
        utmMedium,
        utmCampaign
      }
    })
    console.log(data)
    const { token } = data.signUp
    localStorage.setItem('token', token)
    setEmailError('')
    setIsAuthenticated(true)
    navigate('/bias-scanner')
  }
  return (
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Box sx={{ width: 420, marginLeft: 'auto' }}>
          <Typography variant='h1'>Get started for free</Typography>
          <Typography
            variant='body20Light'
            marginTop='24px'
            marginBottom='24px'
            component='p'
          >
            Don’t let bias hold you back. Hire, build, and retain an equitable
            workforce by reducing bias across the employee lifecycle. Start
            scanning job descriptions, performance feedback, employer brand
            content, and more.
          </Typography>
          <Rule content='No credit card required' />
          <Rule content='AI-powered database, monitored by human experts' />
          <Rule content='Continuously growing dataset of inclusive language' />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            border: '1px solid #E5E5E5',
            borderRadius: '8px',
            padding: '16px 32px',
            width: '420px'
          }}
        >
          <form onSubmit={handleSubmit} noValidate style={{ width: '100%' }}>
            <FormControl fullWidth>
              <FormField label='First Name' name='firstName' type='name' />
              <FormHelperText error>{firstNameError}</FormHelperText>

              <FormField label='Last Name' name='lastName' type='name' />
              <FormHelperText error>{lastNameError}</FormHelperText>
              <FormField label='Work Email' name='email' />
              <FormHelperText error>{emailError}</FormHelperText>
              <FormField label='Organization Name' name='orgName' type='name' />
              <FormHelperText error>{organizationNameError}</FormHelperText>
              <FormField label='Job Title' name='role' type='role' />
              <FormHelperText error>{jobTitleError}</FormHelperText>
              <TextField
                label='How’d you hear about Mathison? (Optional)'
                variant='filled'
                minRows={3}
                multiline
                InputLabelProps={{
                  sx: {
                    fontWeight: 400,
                    lineHeight: '32px',
                    '& .Mui-focused.MuiFilledInput-root': { border: 'none' },
                    '& .MuiInputLabel-asterisk': { color: Color.error }
                  }
                }}
                inputProps={{
                  sx: {
                    paddingLeft: '0px',
                    fontWeight: 700,
                    fontFamily: 'Roboto'
                  }
                }}
                fullWidth
                type={'name'}
                name={'howDidYouHear'}
              />

              <Button
                type='submit'
                variant='contained'
                sx={{ width: 110, marginLeft: 'auto' }}
              >
                Get started
              </Button>
            </FormControl>
          </form>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Signup
